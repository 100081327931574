



















































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetScaleDetailInfo,
  ApiGetScaleQuestion,
  ApiScaleDownloadDetail,
} from "@/apis/index";
import { dosage } from "@/assets/js/dosage";
import { scale } from "@/assets/js/scaleDesc";
import fileDownload from "js-file-download";
import axios from "axios";

@Component({})
export default class ScaleAssessmentView extends Vue {
  mounted() {
    this.init();
  }
  get isCollapse() {
    return this.$store.getters.sidebar.opened;
  }
  //从量表评估结果表格中，获取到的量表id
  scaleId = "";
  //根据量表id，获取量表详情信息后得到的量表类型id
  scaleModuleId = "";
  //量表详情
  scaleDetail: any = {};
  //该量表所属填写说明
  scaleInstructionsList = [] as any;
  evaluationSourceType = "";
  answerList = [];
  relation_show = true;
  relation_title = "知情人与患者关系：";

  async init() {
    //获取量表评估结果id
    this.scaleId = this.$route.query.scaleId as string;
    //获取评估来源类型
    this.evaluationSourceType = this.$route.query
      .evaluationSourceType as string;
    await this.getScaleDetail();
    //获取量表所属填写说明
    this.scaleInstructionsList = (scale as any)[this.scaleModuleId];
    await this.getScaleQuestion();
    this.configAnswer();

    if (!this.isCollapse) {
      (document as any).querySelector(".footer").classList.remove("collapsed");
    } else {
      (document as any).querySelector(".footer").classList.add("collapsed");
    }
    if (this.scaleModuleId == "212") {
      this.relation_title = "原始数据来源是：";
    }
    if (this.scaleModuleId == "213" || this.scaleModuleId == "214") {
      this.relation_title = "本问卷填写者：";
    }
    if (this.scaleModuleId == "215" || this.scaleModuleId == "257") {
      this.relation_show = false;
    }
  }

  //基础信息模块-- -- --
  getScaleDetail() {
    //需要量表id
    const param = {
      id: this.scaleId,
    };
    return new Promise((resolve) => {
      ApiGetScaleDetailInfo(param).then((res) => {
        if (res.success) {
          this.scaleDetail = res.data;
          this.scaleModuleId = res.data.question_result.module;
          // console.log("量表详情：", this.scaleDetail);
          if (this.scaleDetail.last_medication) {
            const medicineList = JSON.parse(this.scaleDetail.last_medication);
            let medicineListStr = "";
            medicineList.forEach((item: any) => {
              medicineListStr =
                medicineListStr + item.name + item.dosage + "，";
            });
            medicineListStr = medicineListStr.slice(
              0,
              medicineListStr.length - 1
            );
            this.scaleDetail.medicineListStr = medicineListStr;
          }

          resolve();
        }
      });
    });
  }
  get showPatientBasic() {
    return this.evaluationSourceType === "0" ? false : true;
  }
  //格式化空数据
  dash(data: any) {
    if (data == null || data === "") {
      data = "-";
    }
    return data;
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }

  //量表问卷模块--
  //是否显示总分
  getVisibleTotalScore() {
    let result = true;
    const moduleId = this.scaleModuleId;
    // const module =
    /**
     * 第一种该问卷没有分数
     * 第二种，有分数没做
     * 第三种，有分数，做了是0分
     */
    if (moduleId == "216" || moduleId == "225") {
      result = false;
    }
    return result;
  }
  getScore() {
    let result = "";
    const detail = this.scaleDetail as any;
    const score = detail.question_result.score;
    const answer = JSON.parse(detail.question_result.score_detail);
    if (
      answer.length == 0 ||
      this.scaleModuleId == "247" ||
      this.scaleModuleId == "248" ||
      this.scaleModuleId == "249" ||
      this.scaleModuleId == "252"
    ) {
      result = " -";
    } else {
      result = score + "分";
    }
    return result;
  }
  //该量表所属问卷内容
  scaleQuestionList: Array<any> = [];
  //获取该量表所属问卷内容
  getScaleQuestion() {
    const param = {
      module: this.scaleModuleId,
    };
    return new Promise((resolve) => {
      ApiGetScaleQuestion(param).then((res) => {
        // console.log(res.data, "res");
        if (res.success) {
          this.scaleQuestionList = res.data;
          // console.log(this.scaleQuestionList, "this.scaleQuestionList");
          this.scaleQuestionList.map((item: any) => {
            // console.log(item.question_type);
            //添加的第一个属性：问题标题列表
            item.myQuestionTitleList = item.question.split("\r\n");
            //截去标题标号,使用自己的标号
            const firstTitle = item.myQuestionTitleList[0];
            // console.log(firstTitle);
            const myTitle = firstTitle.replace(/(^\w*.)|(^\d*.)/, "");
            // console.log(myTitle);
            // console.log(myTitle.startsWith('氏与叶氏（Hoehn & Yahr）分期法'));
            if (myTitle.startsWith("氏与叶氏（Hoehn & Yahr）分期法")) {
              item.myQuestionTitleList[0] = "侯" + myTitle;
            } else {
              item.myQuestionTitleList[0] = myTitle;
            }
            const module = item.module;
            if (module == 221) {
              item.myQuestionTitleList[1] = item.myQuestionTitleList[1].replace(
                /(^\w*.)|(^\d*.)/,
                ""
              );
            }
            //添加的第二个属性：
            item.myChoiceList = JSON.parse(item.choice_item);
            /**
             * 102题型
             * 标题都在question属性中，整体是一个字符串，标题之间用\r\n分开
             * 拆开字符串后增加属性myQuestionTitleList
             * 选项都在choice_item属性中，整体是一个字符串（数组），解析出后
             * 增加属性myChoiceList
             * 注意：102题型所有标题拥有的选项都是一样的
             *
             * 103||104题型 一个题中包含多个小题
             *
             *
             */
          });
          // console.log("这是量表所属作答问题列表：", this.scaleQuestionList);
          resolve();
        }
      });
    });
  }
  //把答案按照规则还原到scaleQuestionList列表中
  configAnswer() {
    let answerList = this.scaleDetail.question_result.score_detail;
    answerList = JSON.parse(answerList);
    this.answerList = answerList;

    const self = this;
    //处理普通单选
    function configCommonRadio(item: any) {
      //该题所在的索引
      const index = item.sid - 1;
      const score = item.s[0].score;
      const answerIndex = item.s[0].choice_index[0];
      const answerName =
        self.scaleQuestionList[index].myChoiceList[answerIndex].choice_name;
      self.scaleQuestionList[index].myScoreResult = answerName;
    }
    //处理嵌套单选
    function configSpeRadio(item: any) {
      const index = item.sid - 1;
      item.s.forEach((ele: any) => {
        self.scaleQuestionList[index].myChoiceList[
          ele.choice_index[0]
        ].myScoreResult = ele.score;
      });
    }
    //处理并排单选
    function configRowRadio(item: any) {
      const index = item.sid - 1;
      const chooseName = item.s[0].choice_index[1] == 1 ? "否" : "是";
      self.scaleQuestionList[index].myChoiceList[0].myScoreResult = chooseName;
    }

    for (let index = 0; index < answerList.length; index++) {
      const item = answerList[index];
      const type = item.question_type;

      switch (type) {
        case 102: {
          configCommonRadio(item);
          break;
        }
        case 103:
        case 104:
        case 105: {
          configSpeRadio(item);
          break;
        }
        case 106: {
          configRowRadio(item);
          break;
        }
      }
    }
    this.scaleQuestionList = [...this.scaleQuestionList];
    // console.log(this.scaleQuestionList, "111");
  }
  //底部按钮模块 --
  back() {
    this.$router.go(-1);
  }

  //打印模块-- -- --
  visiblePrinting = false;
  printContext() {
    const printval = this.$refs["print"];
    //隐藏
    this.visiblePrinting = true;
    //定时器
    setTimeout(() => {
      Vue.prototype.$print(printval);
      this.visiblePrinting = false;
    }, 200);
  }
  downContext() {
    ApiScaleDownloadDetail({ id: this.scaleId }).then((res: any) => {
      if (res.success) {
        axios({
          url: res.data,
          method: "GET",
          responseType: "blob", // 设置响应类型为 blob
        })
          .then((response) => {
            fileDownload(
              response.data,
              this.scaleDetail.patient_name +
                "-" +
                this.scaleInstructionsList.moduleName +
                ".docx"
            );
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
          });
      } else {
        this.$message.error(res.message);
      }
    });
  }
}
